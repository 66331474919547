import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Css } from "../components/css"

const Heading = props => {
  return (
    <h2
      css={css`
        font-size: 2rem;
        line-height: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.2rem;
        max-width: 500px;
      `}
      {...props}
    ></h2>
  )
}

const Section = props => {
  return (
    <section
      css={{
        margin: `3rem 0`,
      }}
      {...props}
    ></section>
  )
}

const SectionTextContainer = props => {
  return <div css={{ padding: `0 1rem` }} {...props}></div>
}

const CookiesPage = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <main
        css={{
          position: "relative",
        }}
      >
        <Section
          css={{
            ...Css.responsiveSection,
            ...Css.container,
            paddingTop: "150px",
          }}
        >
          <Img
            fluid={data.cookie.childImageSharp.fluid}
            alt="Laumes - architekturos studija"
            css={{
              borderRadius: "50%",
              width: "250px",
              height: "250px",
              margin: "auto",
            }}
          />
          <SectionTextContainer>
            <Heading>Cookie Policy</Heading>
            <p css={{ maxWidth: "700px", fontSize: "0.8rem" }}>
              A cookie is a small text file that a website saves on your
              computer or mobile device when you visit the site. This small text
              file enables a site to remember your actions and preferences for a
              specified period of time, so you don’t have to re-enter your login
              or settings information each time you visit a new page on our
              site.
            </p>
          </SectionTextContainer>
        </Section>
      </main>
    </Layout>
  )
}

export default CookiesPage

export const pageQuery = graphql`
  query {
    cookie: file(relativePath: { eq: "cookie.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
